import request from '../../utils/request'

// 管理医生列表
export function userPageListByRoleCode(params: any): Promise<any> {
  return request({
    method: 'GET',
    url: 'mobile/business/sysUser/userPageListByRoleCode?current='+params.current+'&size='+params.size+'&roleCode='+params.roleCode+'&checkerName='+params.checkerName+"&checkerPhone="+params.checkerPhone+"&checkerSex="+params.checkerSex,
  })
}

// 查询用户列表
export function pageList(params: any): Promise<any> {
  return request({
    method: 'GET',
    url: 'mobile/business/sysUser/pageList?current='+params.current+'&size='+params.size+'&checkerName='+params.checkerName+"&checkerPhone="+params.checkerPhone+"&checkerSex="+params.checkerSex,
  })
}

//为管理医生设置所辖用户
export function setClientUser(data: any): Promise<any> {
  return request({
    method: 'POST',
    data,
    url: 'mobile/business/healthDoctor/setClientUser',
  })
}


// 管理医生所辖用户列表
export function clientList(params: any): Promise<any> {
  return request({
    method: 'GET',
    url: 'mobile/business/healthDoctor/clientList?userId='+params.userId,
  })
}
// 管理医生标记需要提醒的用户
export function signClient(data: any): Promise<any> {
  return request({
    method: 'POST',
    data,
    url: 'mobile/business/healthDoctor/signClient',
  })
}

