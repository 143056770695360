
























































































































































































































import { ElForm } from "element-ui/types/form";

import { Component, Vue, Prop } from "vue-property-decorator";
import { pageList,setClientUser,clientList } from "@/serve/healthFile/healthFile";

interface FormData {
  topic: string;
  standardType: string;
  sort: number | string | undefined;
  topicType: string;
  more: string;
  [index: string]: any;
}
@Component({
  name: "AddAndEdit",
})
export default class AddAndEdit extends Vue {
  @Prop({ type: Function }) back!: Function;
  @Prop({ type: String }) doctorUserId!: "";


  
  public userList = [];
  public userListSelect = []

  public searchPhone = "";
  public current = 1;
  public size = 10;
  public total = 0;
  public clientUserIds = []
  public clientUserList = []
  public form={
    phone: "",
    sex:"",
    name: "",
  }

  public created() {
    this.pageListApi();
    this.clientListApi()
  }
  //点击第N页触发获取数据
  public handleCurrentChanges(val: any): void {
    console.log("val", val);

    this.current = val;

    this.pageListApi();
  }

  //点击返回按钮
  public goBack() {
    this.$emit("back");
  }
  // 搜索
  public async search() {
    this.current = 1;
    this.size = 10;
    this.pageListApi();
  }
  // 重置功能
  public resetForm() {
    this.searchPhone = "";
    this.current = 1;
    this.size = 10;
    this.form = {
      phone: "",
      sex:"",
      name: "",
    }
    this.pageListApi();
  }
  public selectionChange(selection: any) {
    const userListSelect: any = this.userListSelect
    const selectionNew: any = selection
    if (selectionNew.length) {
      selectionNew.map((item: any)=>{
      userListSelect.push(item)
      })
      const uniqueArr: any = [...new Set(userListSelect)];  
      this.userListSelect = uniqueArr
      // eslint-disable-next-line camelcase
      this.clientUserIds = uniqueArr.map((obj: { user_id: any }) => obj.user_id);  
    }
    

    console.log("e", selection);
  }
  public cancel(e: any){
    
    
    const userListSelect: any = this.userListSelect
    const id: any = e.user_id

    console.log("e", id);
    // eslint-disable-next-line camelcase
    const userListSelectNew: any = userListSelect.filter((item: { user_id: any }) =>
      
      item.user_id !== id );  
    console.log("e", userListSelectNew);
    this.userListSelect = userListSelectNew
    // eslint-disable-next-line camelcase
    this.clientUserIds = userListSelectNew.map((obj: { user_id: any }) => obj.user_id); 
    
    const r = this.$refs.multipleTable as any
    r.clearSelection()
  }

  public update(){
    this.setClientUserApi()
  }
  

  public async pageListApi() {
    const params = {
      current: this.current,
      size: this.size,
      checkerName:this.form.name,
      checkerPhone:this.form.phone,
      checkerSex:this.form.sex,
    };
    const res = await pageList(params);
    console.log("所有", res);
    this.userList = res.data.records;
    this.total = res.data.total;
  }
  public async setClientUserApi(){
    const params = {
      doctorUserId: this.doctorUserId,
      clientUserIds:this.clientUserIds
    };
    const res = await setClientUser(params);
    this.$message({
          message: '更新成功',
          type: 'success'
    });
    this.clientListApi()
    
  }
  public async clientListApi(){
    const params = {
      userId: this.doctorUserId,
     
    };
    const res = await clientList(params);
    this.clientUserList = res.data
  }
  
}
